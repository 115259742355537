// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.matheditor_matheditor_container__wAkB5{-webkit-overflow-scrolling:touch}.matheditor_matheditor_modal__L2IKB{width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.matheditor_matheditor_modal_content__YYPKf{width:80%;padding:15px;background-color:white;border-radius:8px}.matheditor_matheditor_modal_actions__BP8Gq{display:flex;flex-direction:row;width:100%;align-items:flex-end;justify-content:flex-end;flex-wrap:wrap;padding-top:20px}@media only screen and (max-width: 768px){.matheditor_matheditor_modal_content__YYPKf iframe{height:auto !important;min-height:420px}.matheditor_matheditor_modal_actions__BP8Gq button[class^="button_lsGravityBtn"]{width:auto;min-width:60px}}@media only screen and (max-width: 768px) and (orientation: landscape){.matheditor_matheditor_modal_content__YYPKf iframe{height:auto !important;min-height:210px}.matheditor_matheditor_modal_actions__BP8Gq{padding-top:5px}}
`, "",{"version":3,"sources":["webpack://./src/components/TextEditor/matheditor.module.scss"],"names":[],"mappings":"AACE,wCACE,gCAAiC,CAClC,oCAGC,UAAW,CACX,WAAY,CACZ,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACnB,sBAAuB,CAEvB,4CACE,SAAU,CACV,YAAa,CACb,sBAAuB,CACvB,iBAAkB,CACnB,4CAGC,YAAa,CACb,kBAAmB,CACnB,UAAW,CACX,oBAAqB,CACrB,wBAAyB,CACzB,cAAe,CACf,gBAAiB,CAClB,0CAKE,mDAEG,sBAAuB,CACvB,gBAAiB,CAClB,iFAKC,UAAW,CACX,cAAe,CAChB,CAKP,uEAEK,mDAEG,sBAAuB,CACvB,gBAAiB,CAClB,4CAID,eAAgB,CACjB","sourcesContent":[".matheditor {\n  &_container {\n    -webkit-overflow-scrolling: touch;\n  }\n\n  &_modal {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    &_content {\n      width: 80%;\n      padding: 15px;\n      background-color: white;\n      border-radius: 8px;\n    }\n\n    &_actions {\n      display: flex;\n      flex-direction: row;\n      width: 100%;\n      align-items: flex-end;\n      justify-content: flex-end;\n      flex-wrap: wrap;\n      padding-top: 20px;\n    }\n  }\n\n  @media only screen and (max-width: 768px) {\n    &_modal {\n      &_content {\n        iframe {\n          height: auto !important;\n          min-height: 420px;\n        }\n      }\n\n      &_actions {\n        button[class^=\"button_lsGravityBtn\"] {\n          width: auto;\n          min-width: 60px;\n        }\n      }\n    }\n  }\n\n  @media only screen and (max-width: 768px) and (orientation: landscape) {\n    &_modal {\n      &_content {\n        iframe {\n          height: auto !important;\n          min-height: 210px;\n        }\n      }\n\n      &_actions {\n        padding-top: 5px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"matheditor_container": `matheditor_matheditor_container__wAkB5`,
	"matheditor_modal": `matheditor_matheditor_modal__L2IKB`,
	"matheditor_modal_content": `matheditor_matheditor_modal_content__YYPKf`,
	"matheditor_modal_actions": `matheditor_matheditor_modal_actions__BP8Gq`
};
export default ___CSS_LOADER_EXPORT___;
