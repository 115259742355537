// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lf-spinner{animation-name:rotate;animation-timing-function:linear;animation-iteration-count:infinite}.lf-spinner .lf-path{stroke-linecap:round;animation-name:dash;animation-timing-function:ease-in-out;animation-iteration-count:infinite}.lf-spinner[aria-hidden='true']{display:none}@keyframes rotate{100%{transform:rotate(360deg)}}@keyframes dash{0%{stroke-dasharray:1, 150;stroke-dashoffset:0}50%{stroke-dasharray:90, 150;stroke-dashoffset:-35}100%{stroke-dasharray:90, 150;stroke-dashoffset:-124}}
`, "",{"version":3,"sources":["webpack://./src/components/LoadingIndicator/loadingindicator.scss"],"names":[],"mappings":"AAEE,YACE,qBAAsB,CACtB,gCAAiC,CACjC,kCAAmC,CAHrC,qBAKI,oBAAqB,CACrB,mBAAoB,CACpB,qCAAsC,CACtC,kCAAmC,CARvC,gCAWI,YAAY,CACb,kBAKD,KACE,wBAAyB,CAAA,CAI7B,gBACE,GACE,uBAAwB,CACxB,mBAAoB,CAEtB,IACE,wBAAyB,CACzB,qBAAsB,CAExB,KACE,wBAAyB,CACzB,sBAAuB,CAAA","sourcesContent":["\n  \n  .lf-spinner {\n    animation-name: rotate;\n    animation-timing-function: linear;\n    animation-iteration-count: infinite;\n    & .lf-path {\n      stroke-linecap: round;\n      animation-name: dash;\n      animation-timing-function: ease-in-out;\n      animation-iteration-count: infinite;\n    }\n    &[aria-hidden='true'] {\n      display:none;\n    }\n    \n  }\n  \n  @keyframes rotate {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  \n  @keyframes dash {\n    0% {\n      stroke-dasharray: 1, 150;\n      stroke-dashoffset: 0;\n    }\n    50% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -35;\n    }\n    100% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -124;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
