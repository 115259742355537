// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pe-fieldset{border-width:medium;border-width:initial;border-style:none;border-color:currentColor;border-color:initial;border-image:none;border-image:initial}fieldset{border-width:1px;border-style:solid;border-color:#3e4c59;border-image:none;border-image:initial;margin:0px 2px;padding:0.35em 0.625em 0.75em}.ls-radio-description{margin-bottom:8px}.visually-hidden{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border:0}
`, "",{"version":3,"sources":["webpack://./src/components/RadioButton/radiogroup.scss"],"names":[],"mappings":"AAAA,aACE,mBAAqB,CAArB,oBAAqB,CACrB,iBAAkB,CAClB,yBAAqB,CAArB,oBAAqB,CACrB,iBAAA,CAAA,oBAAqB,CACtB,SAGC,gBAAiB,CACjB,kBAAmB,CACnB,oBAAqB,CACrB,iBAAqB,CAArB,oBAAqB,CACrB,cAAe,CACf,6BAA8B,CAC/B,sBAGC,iBAAkB,CACnB,iBAGC,iBAAkB,CAClB,SAAU,CACV,UAAW,CACX,SAAU,CACV,WAAY,CACZ,eAAgB,CAChB,qBAAsB,CACtB,kBAAmB,CACnB,QAAS","sourcesContent":[".pe-fieldset {\n  border-width: initial;\n  border-style: none;\n  border-color: initial;\n  border-image: initial;\n}\n\nfieldset {\n  border-width: 1px;\n  border-style: solid;\n  border-color: #3e4c59;\n  border-image: initial;\n  margin: 0px 2px;\n  padding: 0.35em 0.625em 0.75em;\n}\n\n.ls-radio-description {\n  margin-bottom: 8px;\n}\n\n.visually-hidden {\n  position: absolute;\n  width: 1px;\n  height: 1px;\n  padding: 0;\n  margin: -1px;\n  overflow: hidden;\n  clip: rect(0, 0, 0, 0);\n  white-space: nowrap; /* added line */\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
