// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_2_ls_progress__7bqii{width:100%;display:flex;flex-direction:column;align-items:flex-end}.ProgressBar_2_ls_progress_bar__NxHc\\+{margin-bottom:8px;width:100%;height:8px;background-color:#e9e9e9;border-radius:20px;box-shadow:inset 1px 1px 4px 0 rgba(0,0,0,0.16);position:relative;overflow:hidden}.ProgressBar_2_ls_progress_fill__0vyuq{top:0;left:0;width:100%;bottom:0;position:absolute;transition:transform 0.2s linear;transform-origin:left;background-color:#047a9c;transform:translateX(-100%)}.ProgressBar_2_ls_progress_percentage__EcyTj{color:#6a7070;font-family:"Open Sans", Calibri, Tahoma, sans-serif;font-size:12px;font-weight:normal}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar_2/ProgressBar_2.module.scss"],"names":[],"mappings":"AAAA,kCACE,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,oBAAqB,CAErB,uCACE,iBAAkB,CAClB,UAAW,CACX,UAAW,CACX,wBAAyB,CACzB,kBAAmB,CACnB,+CAAmD,CACnD,iBAAkB,CAClB,eAAgB,CACjB,uCAGC,KAAM,CACN,MAAO,CACP,UAAW,CACX,QAAS,CACT,iBAAkB,CAClB,gCAAiC,CACjC,qBAAsB,CACtB,wBAAyB,CACzB,2BAA4B,CAC7B,6CAGC,aAAc,CACd,oDAAoD,CACpD,cAAe,CACf,kBAAmB","sourcesContent":[".ls_progress {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n\n  &_bar {\n    margin-bottom: 8px;\n    width: 100%;\n    height: 8px;\n    background-color: #e9e9e9;\n    border-radius: 20px;\n    box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.16);\n    position: relative;\n    overflow: hidden;\n  }\n\n  &_fill {\n    top: 0;\n    left: 0;\n    width: 100%;\n    bottom: 0;\n    position: absolute;\n    transition: transform 0.2s linear;\n    transform-origin: left;\n    background-color: #047a9c;\n    transform: translateX(-100%);\n  }\n\n  &_percentage {\n    color: #6a7070;\n    font-family:\"Open Sans\", Calibri, Tahoma, sans-serif;\n    font-size: 12px;\n    font-weight: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ls_progress": `ProgressBar_2_ls_progress__7bqii`,
	"ls_progress_bar": `ProgressBar_2_ls_progress_bar__NxHc+`,
	"ls_progress_fill": `ProgressBar_2_ls_progress_fill__0vyuq`,
	"ls_progress_percentage": `ProgressBar_2_ls_progress_percentage__EcyTj`
};
export default ___CSS_LOADER_EXPORT___;
