// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Webcomponents.WebcomponentsProgressBar .progress-completion-bar{display:flex;width:100%;height:12px;background:#6A7070}.Webcomponents.WebcomponentsProgressBar.scored{background:repeating-linear-gradient(45deg, #187f48, #187f48 2px, #1ea15b 2px, #1ea15b 5px)}.Webcomponents.WebcomponentsProgressBar.to-review{background:repeating-linear-gradient(-45deg, #ef8600, #ef8600 2px, #FF9A19 2px, #FF9A19 5px)}.Webcomponents.WebcomponentsProgressBar.unsubmitted{background-color:#9B9B9B}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/progressbar.scss"],"names":[],"mappings":"AAAA,iEAMI,YAAa,CACb,UAAW,CACX,WAAY,CACZ,kBAAmB,CATvB,+CAeI,2FAA4F,CAfhG,kDAqBI,4FAA6F,CArBjG,oDA0BI,wBAAyB","sourcesContent":[".Webcomponents.WebcomponentsProgressBar {\n\n  .progress-completion-bar {\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: -webkit-box;\n    display: flex;\n    width: 100%;\n    height: 12px;\n    background: #6A7070;\n  }\n\n  /* line 10, pattern-lab/source/_patterns/01-molecules/15-assignment-list/_legend.scss */\n  &.scored {\n    background: -webkit-repeating-linear-gradient(45deg, #187f48, #187f48 2px, #1ea15b 2px, #1ea15b 5px);\n    background: repeating-linear-gradient(45deg, #187f48, #187f48 2px, #1ea15b 2px, #1ea15b 5px);\n  }\n\n  /* line 14, pattern-lab/source/_patterns/01-molecules/15-assignment-list/_legend.scss */\n  &.to-review {\n    background: -webkit-repeating-linear-gradient(135deg, #ef8600, #ef8600 2px, #FF9A19 2px, #FF9A19 5px);\n    background: repeating-linear-gradient(-45deg, #ef8600, #ef8600 2px, #FF9A19 2px, #FF9A19 5px);\n  }\n\n  /* line 18, pattern-lab/source/_patterns/01-molecules/15-assignment-list/_legend.scss */\n  &.unsubmitted {\n    background-color: #9B9B9B;\n  }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
