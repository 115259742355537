// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_tooltip__illjp{padding:6px 12px;color:#fff !important;text-align:center;background-color:#252525;border-radius:4px;font-size:12px;font-family:"Open Sans semibold";max-width:850px;z-index:1}.tooltip_tooltip__illjp[data-show]{display:block}.tooltip_arrow__DPnCD,.tooltip_arrow__DPnCD::before{position:absolute;width:8px;height:8px;z-index:-1}.tooltip_arrow__DPnCD::before{content:"";transform:rotate(45deg);background:#252525}.tooltip_tooltip__illjp[data-popper-placement="top"] .tooltip_arrow__DPnCD{bottom:-4px;left:-4px !important}.tooltip_tooltip__illjp[data-popper-placement="bottom"] .tooltip_arrow__DPnCD{top:-4px;left:-4px !important}.tooltip_tooltip__illjp[data-popper-placement="left"] .tooltip_arrow__DPnCD{right:0px}.tooltip_tooltip__illjp[data-popper-placement="right"] .tooltip_arrow__DPnCD{left:-8px;bottom:-1px !important}
`, "",{"version":3,"sources":["webpack://./src/components/ToolTip/tooltip.module.scss"],"names":[],"mappings":"AAAA,wBACE,gBAAiB,CACjB,qBAAsB,CACtB,iBAAkB,CAClB,wBAAyB,CACzB,iBAAkB,CAClB,cAAe,CACf,gCAAiC,CACjC,eAAgB,CAChB,SACF,CAAC,mCAGC,aAAc,CACf,oDAIC,iBAAkB,CAClB,SAAU,CACV,UAAW,CACX,UAAW,CACZ,8BAGC,UAAW,CACX,uBAAwB,CACxB,kBAAmB,CACpB,2EAGC,WAAY,CACZ,oBAAqB,CACtB,8EAGC,QAAS,CACT,oBAAqB,CACtB,4EAGC,SAAU,CACX,6EAGC,SAAU,CACV,sBAAuB","sourcesContent":[".tooltip {\n  padding: 6px 12px;\n  color: #fff !important;\n  text-align: center;\n  background-color: #252525;\n  border-radius: 4px;\n  font-size: 12px;\n  font-family: \"Open Sans semibold\";\n  max-width: 850px;\n  z-index: 1\n}\n\n.tooltip[data-show] {\n  display: block;\n}\n\n.arrow,\n.arrow::before {\n  position: absolute;\n  width: 8px;\n  height: 8px;\n  z-index: -1;\n}\n\n.arrow::before {\n  content: \"\";\n  transform: rotate(45deg);\n  background: #252525;\n}\n\n.tooltip[data-popper-placement=\"top\"] .arrow {\n  bottom: -4px;\n  left: -4px !important;\n}\n\n.tooltip[data-popper-placement=\"bottom\"] .arrow {\n  top: -4px;\n  left: -4px !important;\n}\n\n.tooltip[data-popper-placement=\"left\"] .arrow {\n  right: 0px;\n}\n\n.tooltip[data-popper-placement=\"right\"] .arrow {\n  left: -8px;\n  bottom: -1px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `tooltip_tooltip__illjp`,
	"arrow": `tooltip_arrow__DPnCD`
};
export default ___CSS_LOADER_EXPORT___;
