// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container.justify-flex-start{align-items:center;justify-content:flex-start}.dates .pe-proto{max-width:175px;margin-right:2em}.dates .pe-proto{max-width:100%;margin:0px 0px 1em}
`, "",{"version":3,"sources":["webpack://./src/components/InputTime/inputTime.scss"],"names":[],"mappings":"AAAA,mCAEI,kBAAmB,CAEnB,0BAA2B,CAC9B,iBAGG,eAAgB,CAChB,gBAAiB,CACpB,iBAEG,cAAe,CACf,kBAAmB","sourcesContent":[".flex-container.justify-flex-start {\n    -webkit-box-align: center;\n    align-items: center;\n    -webkit-box-pack: start;\n    justify-content: flex-start;\n}\n\n.dates .pe-proto {\n    max-width: 175px;\n    margin-right: 2em;\n}\n.dates .pe-proto {\n    max-width: 100%;\n    margin: 0px 0px 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
