// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressCircle_avgCorrectDiv__oE5-Y{position:relative;background:white;display:flex;flex-direction:column;margin-bottom:24px}.progressCircle_avgCorrectDiv__oE5-Y label{text-align:center;display:block;color:#252525}.progressCircle_avgCorrectDiv__oE5-Y .progressCircle_percCorrectLabel__RWjF1{font-weight:600;color:#6a7070}.progressCircle_avgCorrectDiv__oE5-Y .progressCircle_avgScoreLabel__WXBnK{text-align:center;color:#6a7070;display:block}.progressCircle_avgCorrectDiv__oE5-Y .progressCircle_scoreLabel__9nc6h{margin-top:8px;font-size:14px;line-height:1}.progressCircle_avgCorrectDiv__oE5-Y .progressCircle_outerContainer__VzE8i{display:flex;justify-content:center;align-items:center;flex-direction:column}.progressCircle_avgCorrectDiv__oE5-Y .progressCircle_avgPointsLabel__Bt5h5{display:block;margin-top:8px;font-size:11px;text-align:center;font-weight:400;color:#6a7070}.progressCircle_scoreCircles__2hLYu{position:absolute;top:0px;left:0px;border-radius:50%}
`, "",{"version":3,"sources":["webpack://./src/components/Card/progressCircle.module.scss"],"names":[],"mappings":"AAAA,qCACI,iBAAkB,CAClB,gBAAiB,CACjB,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CALvB,2CAOM,iBAAkB,CAClB,aAAc,CACd,aAAc,CATpB,6EAYM,eAAgB,CAChB,aAAc,CAbpB,0EAgBM,iBAAkB,CAClB,aAAc,CACd,aAAc,CAlBpB,uEAqBM,cAAe,CACf,cAAe,CACf,aAAc,CAvBpB,2EA2BM,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,qBAAsB,CA9B5B,2EAkCM,aAAc,CAChB,cAAe,CACf,cAAe,CACf,iBAAkB,CAClB,eAAgB,CAChB,aAAc,CACb,oCAID,iBAAkB,CAClB,OAAQ,CACR,QAAS,CACT,iBAAkB","sourcesContent":[".avgCorrectDiv {\n    position: relative;\n    background: white;\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 24px;\n    label {\n      text-align: center;\n      display: block;\n      color: #252525;\n    }\n    .percCorrectLabel {\n      font-weight: 600;\n      color: #6a7070;\n    }\n    .avgScoreLabel {\n      text-align: center;\n      color: #6a7070;\n      display: block;\n    }\n    .scoreLabel {\n      margin-top: 8px;\n      font-size: 14px;\n      line-height: 1;\n    }\n\n    .outerContainer {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n    }\n\n    .avgPointsLabel {\n      display: block;\n    margin-top: 8px;\n    font-size: 11px;\n    text-align: center;\n    font-weight: 400;\n    color: #6a7070;\n    }\n\n  }\n  .scoreCircles {\n    position: absolute;\n    top: 0px;\n    left: 0px;\n    border-radius: 50%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avgCorrectDiv": `progressCircle_avgCorrectDiv__oE5-Y`,
	"percCorrectLabel": `progressCircle_percCorrectLabel__RWjF1`,
	"avgScoreLabel": `progressCircle_avgScoreLabel__WXBnK`,
	"scoreLabel": `progressCircle_scoreLabel__9nc6h`,
	"outerContainer": `progressCircle_outerContainer__VzE8i`,
	"avgPointsLabel": `progressCircle_avgPointsLabel__Bt5h5`,
	"scoreCircles": `progressCircle_scoreCircles__2hLYu`
};
export default ___CSS_LOADER_EXPORT___;
