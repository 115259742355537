// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Webcomponents.General.Collections.SortableTable.sortable thead th{border-top:1px solid #d9d9d9;border-bottom:1px solid #d9d9d9;padding-top:14px;padding-bottom:14px}.Webcomponents.General.Collections.SortableTable.sortable thead th svg{color:#6a7070}.Webcomponents.General.Collections.SortableTable.sortable tbody th{font-weight:400}.Webcomponents.General.Collections.SortableTable.sortable [role=button]{cursor:pointer}.Webcomponents.General.Collections.SortableTable.sortable [role=columnheader]{cursor:pointer}
`, "",{"version":3,"sources":["webpack://./src/components/SortableTable/reports-table.scss"],"names":[],"mappings":"AAEA,mEAEQ,4BAA6B,CAC7B,+BAAgC,CAChC,gBAAiB,CACjB,mBAAoB,CAL5B,uEAOY,aAAc,CAP1B,mEAaY,eAAgB,CAb5B,wEAkBQ,cAAe,CAlBvB,8EAsBQ,cAAe","sourcesContent":["/* Styles for sortable reports table */\n// ++++++++++++ NEED STAY START +++++++++++++\n.Webcomponents.General.Collections.SortableTable.sortable {\n    thead th {\n        border-top: 1px solid #d9d9d9;\n        border-bottom: 1px solid #d9d9d9;\n        padding-top: 14px;\n        padding-bottom: 14px;\n        svg {\n            color: #6a7070;\n        }\n    }\n\n    tbody {\n        th {\n            font-weight: 400;\n        }\n    }\n\n    [role=button] {\n        cursor: pointer;\n    }\n\n    [role=columnheader] {\n        cursor: pointer;\n    }\n}\n// ++++++++++++ NEED STAY END +++++++++++++"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
