// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container.justify-flex-start{align-items:center;justify-content:flex-start}.flex-container{display:flex;align-items:center;justify-content:space-between;flex-direction:row}
`, "",{"version":3,"sources":["webpack://./src/components/InputDate/inputDate.scss"],"names":[],"mappings":"AAAA,mCAEI,kBAAmB,CAEnB,0BAA2B,CAC9B,gBAGG,YAAa,CAEb,kBAAmB,CAEnB,6BAA8B,CAG9B,kBAAmB","sourcesContent":[".flex-container.justify-flex-start {\n    -webkit-box-align: center;\n    align-items: center;\n    -webkit-box-pack: start;\n    justify-content: flex-start;\n}\n\n.flex-container {\n    display: flex;\n    -webkit-box-align: center;\n    align-items: center;\n    -webkit-box-pack: justify;\n    justify-content: space-between;\n    -webkit-box-orient: horizontal;\n    -webkit-box-direction: normal;\n    flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
