// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchSuggestions_suggestions_container__dyZqM{display:flex;flex-direction:column;border-radius:8px;box-shadow:0 5px 22px 4px rgba(0,0,0,0.03),0 7px 8px -4px rgba(0,0,0,0.05);border:solid 1px rgba(151,151,151,0.07);background-color:#ffffff;margin:0px;padding:12px 0px;margin-top:4px;position:absolute;z-index:150;width:100%;list-style:none}.searchSuggestions_suggestions_title__uYiS0{font-size:12px;color:#252525;text-align:right;width:100%;padding:0px 24px}.searchSuggestions_suggestions_results__W0oXR{padding:0px;width:100%;margin:0px;margin-top:5px;list-style:none}.searchSuggestions_suggestions_results_hr__1GB9H{background-color:#e9e9e9;margin:8px 24px;height:1px;border:1px}.searchSuggestions_suggestions_results_item__3yW4H{font-size:14px;color:#252525;padding:8px 24px}.searchSuggestions_suggestions_results_item__3yW4H:hover{background-color:#f5f5f5;cursor:pointer}.searchSuggestions_suggestions_results_item__3yW4H:focus{background-color:#f5f5f5;cursor:pointer;outline:none}.searchSuggestions_suggestions_results_item_icon__EtxTF{fill:#6a7070 !important;margin-right:12px}
`, "",{"version":3,"sources":["webpack://./src/components/SearchSuggestions/searchSuggestions.module.scss"],"names":[],"mappings":"AACE,gDACE,YAAa,CACb,qBAAsB,CACtB,iBAAkB,CAClB,0EACoC,CACpC,uCAA2C,CAC3C,wBAAyB,CACzB,UAAW,CACX,gBAAiB,CACjB,cAAe,CACf,iBAAkB,CAClB,WAAY,CACZ,UAAW,CACX,eAAgB,CACjB,4CAEC,cAAe,CACf,aAAc,CACd,gBAAiB,CACjB,UAAW,CACX,gBAAiB,CAClB,8CAEC,WAAY,CACZ,UAAW,CACX,UAAW,CACX,cAAe,CACf,eAAgB,CAEhB,iDACE,wBAAyB,CACzB,eAAgB,CAChB,UAAW,CACX,UAAW,CACZ,mDAGC,cAAe,CACf,aAAc,CACd,gBAAiB,CAHlB,yDAMG,wBAAyB,CACzB,cAAe,CAPlB,yDAUG,wBAAyB,CACzB,cAAe,CACf,YAAa,CACd,wDAGC,uBAAwB,CACxB,iBAAkB","sourcesContent":[".suggestions {\n  &_container {\n    display: flex;\n    flex-direction: column;\n    border-radius: 8px;\n    box-shadow: 0 5px 22px 4px rgba(0, 0, 0, 0.03),\n      0 7px 8px -4px rgba(0, 0, 0, 0.05);\n    border: solid 1px rgba(151, 151, 151, 0.07);\n    background-color: #ffffff;\n    margin: 0px;\n    padding: 12px 0px;\n    margin-top: 4px;\n    position: absolute;\n    z-index: 150;\n    width: 100%;\n    list-style: none;\n  }\n  &_title {\n    font-size: 12px;\n    color: #252525;\n    text-align: right;\n    width: 100%;\n    padding: 0px 24px;\n  }\n  &_results {\n    padding: 0px;\n    width: 100%;\n    margin: 0px;\n    margin-top: 5px;\n    list-style: none;\n\n    &_hr {\n      background-color: #e9e9e9;\n      margin: 8px 24px;\n      height: 1px;\n      border: 1px;\n    }\n\n    &_item {\n      font-size: 14px;\n      color: #252525;\n      padding: 8px 24px;\n\n      &:hover {\n        background-color: #f5f5f5;\n        cursor: pointer;\n      }\n      &:focus {\n        background-color: #f5f5f5;\n        cursor: pointer;\n        outline: none;\n      }\n\n      &_icon {\n        fill: #6a7070 !important;\n        margin-right: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suggestions_container": `searchSuggestions_suggestions_container__dyZqM`,
	"suggestions_title": `searchSuggestions_suggestions_title__uYiS0`,
	"suggestions_results": `searchSuggestions_suggestions_results__W0oXR`,
	"suggestions_results_hr": `searchSuggestions_suggestions_results_hr__1GB9H`,
	"suggestions_results_item": `searchSuggestions_suggestions_results_item__3yW4H`,
	"suggestions_results_item_icon": `searchSuggestions_suggestions_results_item_icon__EtxTF`
};
export default ___CSS_LOADER_EXPORT___;
