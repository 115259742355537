// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message_message_box__yKqcm{min-height:auto}
`, "",{"version":3,"sources":["webpack://./src/components/Message/message.module.scss"],"names":[],"mappings":"AAAA,4BAAa,eAAgB","sourcesContent":[".message_box{min-height: auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message_box": `message_message_box__yKqcm`
};
export default ___CSS_LOADER_EXPORT___;
