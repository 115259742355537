// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar_avatar__2pM2u{width:48px;height:48px;border-radius:100%;background-color:#6a7070;display:flex;flex-direction:column;justify-content:center;align-items:center}.avatar_avatar_text__GUOQ1{font-size:16px;margin:0px;color:white}
`, "",{"version":3,"sources":["webpack://./src/components/Avatar/avatar.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAW,CACX,WAAY,CACZ,kBAAmB,CACnB,wBAAyB,CACzB,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,kBAAmB,CAEnB,2BACE,cAAe,CACf,UAAW,CACX,WAAY","sourcesContent":[".avatar {\n  width: 48px;\n  height: 48px;\n  border-radius: 100%;\n  background-color: #6a7070;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  &_text {\n    font-size: 16px;\n    margin: 0px;\n    color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `avatar_avatar__2pM2u`,
	"avatar_text": `avatar_avatar_text__GUOQ1`
};
export default ___CSS_LOADER_EXPORT___;
